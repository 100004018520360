import React, { Component } from 'react'

import { Wrap } from './styled';

export default class Auth extends Component {
  render() {
    return (
      <Wrap>
        Auth
      </Wrap>
    )
  }
}
